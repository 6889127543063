import React from "react";

//Style
import "./BodyRenovationFormScreen.css";

//Data
import formInputsData from "../../json/FormSecondStep/formSecondStepRenovation.json";

//Components
import FormWithSteps from "../FormWithSteps/FormWithSteps";

const BodyRenovationFormScreen = () => {
  return (
    <section className="renovation-form-section">
      <div className="rf-subcontainer">
        <img src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/step-2-deco-img.webp" alt="" className="rf-deco-img" loading="lazy" />
        <div className="rf-form-container">
          <FormWithSteps inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  );
};

export default BodyRenovationFormScreen;
