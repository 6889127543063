import React from "react";

//Style
import './BodyCreditTypeScreen.css';

//Component
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const BodyCreditTypeScreen = ({buttons}) => {
  return (
    <section className="credit-type-section">
      <div className="ct-subcontainer">
        <div className="ct-info-container">
          <div className="ct-title-container">
            <h1 className="ct-title">
              Selecciona el tipo <strong>de crédito que deseas crear</strong>
            </h1>
          </div>
          <div className="ct-btn-container">
            <ButtonComponent btn={buttons[0]} />
            <ButtonComponent btn={buttons[1]} />
          </div>
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/broker-form-page/type-credit-deco-img.webp"
          alt=""
          className="ct-deco-img"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default BodyCreditTypeScreen;
