import React from 'react'

//Data
import creditTypeButtons from '../../json/CreditTypePage/CTButtons.json';

//Component
import ContactNavbar from '../../components/ContactNavbar/ContactNavbar'
import Navbar from '../../components/Navbar/Navbar'
import BodyCreditTypeScreen from '../../components/BodyCreditTypeScreen/BodyCreditTypeScreen'
import WhatsappButton from '../../components/WhatsappButton/WhatsappButton';

const CreditTypeScreen = ({navbarData}) => {
  return (
    <>
        <WhatsappButton />
        <ContactNavbar social={navbarData.data} />
        <Navbar />
        <BodyCreditTypeScreen buttons={creditTypeButtons.data} />
    </>
  )
}

export default CreditTypeScreen