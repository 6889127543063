import React from 'react'

const NotfoundScreen = () => {
  return (
    <div>
        <h1>Hola mundo desde la pagina del 404</h1>
    </div>
  )
}

export default NotfoundScreen