import React, { useContext } from "react";

//Components
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyDocPolicyScreen from "../../components/BodyDocPolicyScreen/BodyDocPolicyScreen";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const DocPolicyScreen = ({ navbarData }) => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      {loading && <LoadingScreen />}
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BodyDocPolicyScreen />
    </>
  );
};

export default DocPolicyScreen;
