import React from 'react'

//Style
import './BodyThanksScreen.css';

//Components
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const BodyThanksScreen = ({buttons}) => {
  return (
    <section className='thanks-section'>
        <div className="thanks-subcontainer">
            <img src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/thanks-page/thanks-deco-img.webp" className='thanks-deco-img' alt="" loading='lazy' />
            <div className="thanks-btn-container">
                <ButtonComponent btn={buttons[0]} />
            </div>
        </div>
    </section>
  )
}

export default BodyThanksScreen