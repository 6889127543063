import React from "react";

//Style
import "./PaymentSection.css";

//Components
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const PaymentSection = ({ buttons }) => {
  return (
    <section className="payment-methods-section" id="pay-forms">
      <div className="payment-methods-subcontainer">
        <div className="pm-info-container">
          <h1 className="pm-info-title">
            <strong>MEDIOS Y FORMAS</strong> DE PAGO DE LA CUOTA DE TU CRÉDITO
          </h1>
          <div className="pm-info-content">
            <p>
              <strong>
                Ingresa desde cualquier dispositivo móvil y realiza el pago de
                tu cuota sin salir de tu casa
              </strong>{" "}
              u oficina con tarjeta de crédito, débito, PSE y/o de manera física
              en cualquiera de los 9.000 puntos Efecty en todo el país.
            </p>
            <p>
              Sabías que 6 de cada 10 personas que compra Seguro Todo Riesgo,
              ¿necesitan financiación? Recuerda que para financiar con
              CrediSeguro,{" "}
              <strong>
                solo necesitas la firma digital del cliente y el pago de la
                primera cuota.
              </strong>
            </p>
          </div>
        </div>
        <div className="pm-options-container">
          <div className="option-container">
            <img className="pm-opt-img" src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/pc-img.webp" alt="" loading="lazy" />
            <div className="pm-btn-container">
              <ButtonComponent btn={buttons[0]} />
            </div>
          </div>
          <div className="option-container">
            <img className="pm-opt-img" src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/efecty-img.webp" alt="" loading="lazy" />
            <div className="pm-btn-container">
              <ButtonComponent btn={buttons[1]} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;
