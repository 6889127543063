import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//Style
import "./FormDocs.css";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const FormDocs = ({ inputData }) => {
  const { setLoading } = useContext(LoadingContext);

  const location = useLocation();

  const history = useNavigate();

  const [plate, setPlate] = useState("");

  const [insuranceVal, setInsuranceVal] = useState("");

  const [doc, setDoc] = useState(null);

  const [ccType, setCcType] = useState("");

  const [docType, setDocType] = useState("");

  const [inputTextInfo, setInputTextInfo] = useState("");

  const handleInput = (e) => {
    if (e.target.name === "cc-type") {
      setCcType(e.target.value);
    }

    if (e.target.name === "insurer-name") {
      if (e.target.value.includes("MUNDIAL")) {
        const aa = e.target.value.split("+");
        const b = `formato_${aa[0]}`;
        setDocType(b);
        setInsuranceVal(aa[1]);
      } else {
        setInsuranceVal(e.target.value);
      }
    }
    if (e.target.name === "doc_number") {
      const valueFixed = e.target.value.replace(/\D/g, "");
      setInputTextInfo(valueFixed);
    }
    if (e.target.name === "plate_number") {
      const valueFixed = e.target.value.toUpperCase().replace(/\s/g, "");
      setPlate(valueFixed);
    }
    if (e.target.name === "doc") {
      setDoc(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const baseEndpoint = inputData.endpoint;

    const uuid = uuidv4();
    const valuesArr = uuid.split("-");

    let endpointWithParams = "";

    if (location.pathname === "/prototipo-formulario-ocr") {
      const id = valuesArr[valuesArr.length - 1];

      localStorage.setItem("form_id", id);

      formData.append("file", doc, doc.name);
      formData.append("type_doc", ccType);
      formData.append("id", id);
      formData.append("insurance", "CC");
      console.log(doc);

      endpointWithParams = `${baseEndpoint}?_id=${id}&insurance=CC`;
    } else {
      const idFromLS = localStorage.getItem("form_id");

      console.log(doc.type);

      formData.append("file", doc, doc.name);
      formData.append("type_doc", docType ? docType : "other");
      formData.append("id", idFromLS);
      formData.append("insurance", insuranceVal);

      console.log(doc);
      console.log(insuranceVal);

      endpointWithParams = `${baseEndpoint}?_id=${idFromLS}&insurance=${insuranceVal}`;
    }

    console.log(endpointWithParams);

    fetch(endpointWithParams, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        setPlate("");
        setDoc(null);
        setInputTextInfo("");
        history(inputData.redirect);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      className="form-container-docs"
    >
      <div className="txt-input-container">
        {inputData.headerFields &&
          inputData.headerFields.map((field, i) => {
            return (
              <label
                className={`select-label ${field.isHidden ? "hide-label" : ""}`}
                key={i}
              >
                {field.title}
                <select
                  onChange={(e) => handleInput(e)}
                  name={field.name}
                  required={field.isRequired}
                >
                  <option value="">--Ninguno--</option>
                  {field.options.map((opt, i) => {
                    return (
                      <option
                        key={i}
                        value={opt.value}
                        selected={opt.isSelected}
                      >
                        {opt.title}
                      </option>
                    );
                  })}
                </select>
              </label>
            );
          })}
        {!inputData.headerFields && (
          <>
            <label>
              No. de Documento Titular del Crédito*
              <input
                onChange={(e) => handleInput(e)}
                type="text"
                value={inputTextInfo}
                name="doc_number"
                required
              />
            </label>
            <label>
              Numero de Placa*
              <input
                onChange={(e) => handleInput(e)}
                type="text"
                value={plate}
                name="plate_number"
                required
              />
            </label>
          </>
        )}
      </div>
      <div className="inputs-file-container">
        {inputData.inputs.map((input, i) => {
          return (
            <label key={i}>
              {input.labelTitle}
              <input
                type="file"
                onChange={(e) => handleInput(e)}
                accept={input.accept}
                name={input.name}
                required={input.isRequired}
                id={input.id}
              />
              <p className="input-subtitle">{input.labelSubtitle}</p>
            </label>
          );
        })}
      </div>
      <div className="form-doc-btn-container">
        <button title="Enviar" className="form-doc-btn" type="submit">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default FormDocs;
