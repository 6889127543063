import React from 'react'

//Style
import './BodyProtoFormOcrScreen.css';

//Data
import formInputsData from '../../json/formDocs/formDocsIDUser.json';

//Components
import FormDocs from '../FormDocs/FormDocs'

const BodyProtoFormOcrScreen = () => {
  return (
    <section className="proto-ocr-docs-section">
      <div className="po-subcontainer">
        <img src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/ncd-deco-img.webp" alt="" className="po-deco-img" loading="lazy" />
        <div className="po-form-container">
          <FormDocs inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  )
}

export default BodyProtoFormOcrScreen