import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-grid-carousel";

//Style
import "./CounterSection.css";

//Components
import Counter from "../Counter/Counter";

const CounterSection = ({ infoCounter }) => {
  const elements = [];

  infoCounter.map((counter, i) => {
    elements.push(<Counter key={i} info={counter} />);
  });

  return (
    <section className="counter-section">
      <div className="supercontainer">
        <div className="bg-skew top"></div>
        <div className="counter-subcontainer">
          <h1 className="counter-title">Nuestras Cifras</h1>
          <div className="counter-info-container-tablet">
            <Carousel
              cols={2}
              rows={1}
              loop
              autoplay={3000}
              showDots={true}
              mobileBreakpoint={479}
              hideArrow={true}
            >
              {infoCounter.map((info, i) => {
                return (
                  <Carousel.Item key={i}>
                     <Counter info={info} />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
          <div className="counter-info-container-mobile">
            <CarouselDos
              children={elements}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              isAutoPlaying={true}
              hasDotButtons={"bottom"}
              hasLeftButton={false}
              hasRightButton={false}
              hasMediaButton={false}
              hasIndexBoard={false}
              hasThumbnails={false}
              hasSizeButton={false}
              transitionSpeed={0.1}
            />
          </div>
          <div className="counter-info-container">
            {infoCounter.map((info, i) => {
              return <Counter key={i} info={info} />;
            })}
          </div>
        </div>
        <div className="bg-skew bottom"></div>
      </div>
    </section>
  );
};

export default CounterSection;
