import React from "react";

//Style
import './BodyNewCreditDocScreen.css';

//Data
import formInputsData from '../../json/formDocs/formDocsNewCredit.json';

//Components
import FormDocs from "../FormDocs/FormDocs";

const BodyNewCreditDocScreen = () => {
  return (
    <section className="new-credit-docs-section">
      <div className="ncd-subcontainer">
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/ncd-deco-img.webp"
          alt=""
          className="ncd-deco-img"
          loading="lazy"
        />
        <div className="ncd-form-container">
            <FormDocs inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  );
};

export default BodyNewCreditDocScreen;
