import React from "react";

//Data
import dataBtn from '../../json/ThanksPage/thanksBtns.json';

//Components
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyThanksScreen from "../../components/BodyThanksScreen/BodyThanksScreen";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";

const ThanksScreen = ({navbarData}) => {
  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BodyThanksScreen buttons={dataBtn.data} />
    </>
  );
};

export default ThanksScreen;
