import React from "react";

//Style
import "./BodyCreateCreditFirstScreen.css";

//Component
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const BodyCreateCreditFirstScreen = ({ buttons }) => {
  return (
    <section className="create-credit-first-section">
      <div className="ccf-subcontainer">
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/broker-form-page/wwu-deco-img.webp"
          alt=""
          className="ccf-deco-img"
          loading="lazy"
        />
        <div className="ccf-info-container">
          <div className="ccf-title-container">
            <h1 className="ccf-title">
              Solicita tu crédito en <strong>2 sencillos pasos:</strong>
            </h1>
            <ul className="ccf-subtitle">
              <li>Adjunta los documentos</li>
              <li>Diligencia la información</li>
            </ul>
          </div>
          <div className="ccf-btn-container">
            <ButtonComponent btn={buttons[0]} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BodyCreateCreditFirstScreen;
