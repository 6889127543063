import React, { useContext } from "react";

//Components
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyRenovationScreen from "../../components/BodyRenovationDocsScreen/BodyRenovationDocsScreen";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const RenovationDocScreen = ({ navbarData }) => {
  const { loading } = useContext(LoadingContext);
  return (
    <>
      {loading && <LoadingScreen />}
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BodyRenovationScreen />
    </>
  );
};

export default RenovationDocScreen;
