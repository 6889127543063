import React from "react";

//Style
import "./BodyRenovationDocsScreen.css";

//Data
import formInputsData from "../../json/formDocs/formDocsRenovaton.json";

//Components
import FormDocs from "../FormDocs/FormDocs";

const BodyRenovationScreen = () => {
  return (
    <section className="renovation-docs-section">
      <div className="rd-subcontainer">
        <img src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/ncd-deco-img.webp" alt="" className="rd-deco-img" loading="lazy" />
        <div className="rd-form-container">
          <FormDocs inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  );
};

export default BodyRenovationScreen;
