import React from 'react'

//Style
import './BodyWorkFormScreen.css';

//Components
import FormWWU from '../FormWWU/FormWWU'

const BodyWorkFormScreen = () => {
  return (
    <section className='work-with-us-section'>
        <div className="wwu-subcontainer">
            <img src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/broker-form-page/wwu-deco-img.webp" alt="" className="wwu-deco-img" loading='lazy' />
            <div className="wwu-form-container">
                <FormWWU />
            </div>
        </div>
    </section>
  )
}

export default BodyWorkFormScreen