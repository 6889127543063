import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//Style
import "./FormWithSteps.css";

//Componente
import { LoadingContext } from "../../context/LoadingContext";

const FormWithSteps = ({ inputData }) => {
  const history = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const [stepNumber, setStepNumber] = useState(0);

  const [progress, setProgress] = useState(1);

  const progressFraction = 100 / inputData.steps.length;

  const [formData, setFormData] = useState({});

  useEffect(() => {
    switch (inputData.type) {
      case "Nuevo":
        setFormData({
          req_type: inputData.type,
          first_name: "",
          last_name: "",
          doc_type: "",
          num_document: "",
          birthdate: "",
          genre: "",
          email: "",
          phone: "",
          job: "",
          salary: "",
          country: "",
          department: "",
          city: "",
          address: "",
          num_policy: "",
          insurer_place: "",
          insurer: "",
          name_broker: "",
          nit_broker: "",
          broker: "",
          plate: "",
          init_term: "",
          total_annual: "",
          init_credit: "",
          num_shares: "",
        });
        break;
      case "Renovación":
        setFormData({
          req_type: inputData.type,
          first_name: "",
          last_name: "",
          doc_type: "",
          num_document: "",
          num_policy: "",
          insurer: "",
          name_broker: "",
          nit_broker: "",
          broker: "",
          total_annual: "",
          init_credit: "",
          num_shares: "",
        });
        break;
    }
  }, []);

  useEffect(() => {
    if (stepNumber === 1 && inputData.type === "Nuevo") {
      const selectDept = document.getElementById("department");

      const body = {
        id_department: "",
        get_cities: false,
        get_departments: true,
      };
      fetch("https://crediseguro-back.click/getLocation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          data.Departamento.map((dept, i) => {
            const option = document.createElement("option");
            option.text = dept.Nombre;
            option.value = dept.Id;
            selectDept.appendChild(option);

            const optionsArray = Array.from(selectDept.options);

            optionsArray.sort((a, b) => a.text.localeCompare(b.text));

            selectDept.innerHTML = "";

            optionsArray.forEach((option) => {
              selectDept.appendChild(option);
            });
          });
        });
      try {
      } catch (error) {}
    }
  }, [stepNumber]);

  const handleForm = (e) => {
    if (e.target.title === "text") {
      if (e.target.name === "plate") {
        e.target.value = e.target.value.replace(/\s/g, "").toUpperCase();
      }

      e.target.value = e.target.value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "select") {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "number") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");

      if (e.target.name === "init_credit") {
        e.target.value = parseInt(e.target.value, 10);

        if (e.target.value > 10000000) {
          e.target.value = 10000000;
        }
      }

      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "date") {
      const birthdate = new Date(e.target.value);
      const today = new Date();
      const age = today.getFullYear() - birthdate.getFullYear();

      if (e.target.name === "birthdate" && age < 18) {
        e.target.nextElementSibling.innerText = "Debes ser mayor de edad";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "init_term") {
      const currentDate = new Date(e.target.value);
      const limitDate = new Date();

      limitDate.setDate(limitDate.getDate() + 45);

      if (currentDate > limitDate) {
        e.target.nextElementSibling.innerText =
          "La vigencia inicial no debe ser mayor a 45 dias";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title === "email") {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexEmail.test(e.target.value)) {
        e.target.nextElementSibling.innerText = "Ingresa un correo valido";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.title == "phone") {
      e.target.value = e.target.value.replace(/\s/g, "").replace(/\D/g, "");

      if (e.target.value.length > 10) {
        e.target.value = e.target.value.substring(0, 10);
      }

      if (
        e.target.value[0] !== "3" ||
        e.target.value[1] === "3" ||
        e.target.value.trim() === "" ||
        e.target.value.length < 10
      ) {
        e.target.nextElementSibling.innerText = "Ingresa un numero valido";
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        if (
          e.target.name == "00N5G00000WiqJe" &&
          e.target.value == formData["00N5G00000WiqHn"]
        ) {
          e.target.nextElementSibling.innerText =
            "El numero no puede ser igual";
          e.target.nextElementSibling.classList.remove("hide");
        } else {
          e.target.nextElementSibling.classList.add("hide");
        }
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      fetch(inputData.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            history(inputData.redirect);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (inputData.type === "Nuevo") {
      switch (stepNumber) {
        case 0:
          if (
            formData.first_name &&
            formData.first_name.trim() !== "" &&
            formData.last_name &&
            formData.last_name.trim() !== "" &&
            formData.doc_type &&
            formData.doc_type.trim() !== "" &&
            formData.num_document &&
            formData.num_document.trim() !== "" &&
            formData.birthdate &&
            formData.birthdate.trim() !== "" &&
            formData.genre &&
            formData.genre.trim() !== "" &&
            formData.email &&
            formData.email.trim() !== "" &&
            formData.phone &&
            formData.phone.trim() !== "" &&
            formData.job &&
            formData.job.trim() !== "" &&
            formData.salary &&
            formData.salary.trim() !== ""
          ) {
            setStepNumber(stepNumber + 1);
            setProgress(progress + 1);
          }
          break;
        case 1:
          if (
            formData.country &&
            formData.country.trim() !== "" &&
            formData.department &&
            formData.department.trim() !== "" &&
            formData.city &&
            formData.city.trim() !== "" &&
            formData.address &&
            formData.address.trim() !== ""
          ) {
            setStepNumber(stepNumber + 1);
            setProgress(progress + 1);
          }
          break;
      }
    } else {
      switch (stepNumber) {
        case 0:
          if (
            formData.first_name &&
            formData.first_name.trim() !== "" &&
            formData.last_name &&
            formData.last_name.trim() !== "" &&
            formData.doc_type &&
            formData.doc_type.trim() !== "" &&
            formData.num_document &&
            formData.num_document.trim() !== ""
          ) {
            setStepNumber(stepNumber + 1);
            setProgress(progress + 1);
          }
          break;
      }
    }
  };

  const handleDependent = (e, childrenName) => {
    const selectCity = document.getElementById(childrenName);
    if (e.target.id === "department") {
      try {
        selectCity.innerHTML = "";
        const noneOption = document.createElement("option");
        noneOption.text = "--Ninguno--";
        noneOption.value = "";

        selectCity.appendChild(noneOption);
        const body = {
          id_department: e.target.value,
          get_cities: true,
          get_departments: false,
        };
        fetch("https://crediseguro-back.click/getLocation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.Ciudad) {
              const noneOption = document.createElement("option");
              noneOption.text = "--Ninguno--";
              noneOption.value = "";

              selectCity.appendChild(noneOption);

              data.Ciudad.map((city, i) => {
                const option = document.createElement("option");
                option.text = city.Nombre;
                option.value = city.Id;
                selectCity.appendChild(option);

                const optionsArray = Array.from(selectCity.options);

                optionsArray.sort((a, b) => a.text.localeCompare(b.text));

                selectCity.innerHTML = "";

                optionsArray.forEach((option) => {
                  selectCity.appendChild(option);
                });
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleGetCode = () => {
    const code = localStorage.getItem("form_id");
    console.log(code);
    setFormData({
      req_type: inputData.type,
      first_name: "Ana Lisa",
      last_name: "Melano",
      doc_type: "CC",
      num_document: "123456789",
      birthdate: "1998-10-13",
      genre: "Femenino",
      email: "analisa@correo.com",
      phone: "3123345612",
      job: "Independiente/Comerciante",
      salary: "1500000",
      country: "",
      department: "",
      city: "",
      address: "",
      num_policy: "1234",
      insurer_place: "Colombia",
      insurer: "Allianz Colombia S.A.",
      name_broker: "Axa",
      nit_broker: "1234",
      broker: "Carlos Melano",
      plate: "ABC123",
      init_term: "2023-11-11",
      total_annual: "10000",
      init_credit: "1000",
      num_shares: "3",
    });
  };

  return (
    <div className="form-with-steps-container">
      <div className="header-title-container">
        <h1>Solicitud de {inputData.headerTitle} Persona Natural</h1>
      </div>
      <div className="steps-graph-container">
        {inputData.steps[stepNumber].subtitleStep}
        <div className="step-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${progressFraction * progress}%` }}
          ></div>
        </div>
      </div>
      <div className="copy-code-container">
        <button onClick={handleGetCode}>Obtener Datos</button>
      </div>
      <form
        className="fws-form-container"
        onChange={handleForm}
        onSubmit={handleSubmit}
      >
        <div className="current-step">
          {inputData.steps[stepNumber].inputs.map((input, i) => {
            if (input.isSelect) {
              return (
                <label key={i}>
                  {input.title}
                  <select
                    value={formData[input.name]}
                    name={input.name}
                    title={input.typeVal}
                    id={input.name}
                    required
                  >
                    <option value="">--Ninguno--</option>
                    {input.options.map((option, i) => {
                      return (
                        <option key={i} value={option.title}>
                          {option.title}
                        </option>
                      );
                    })}
                  </select>
                  <span className="mandatory-text hide">Campo Obligatorio</span>
                </label>
              );
            }
            if (input.isDependent) {
              return (
                <label key={i}>
                  {input.title}
                  <select
                    value={formData[input.name]}
                    name={input.name}
                    id={input.name}
                    title={input.typeVal}
                    onChange={(e) => handleDependent(e, input.childrenName)}
                    required
                  >
                    <option value="">--Ninguno--</option>
                    {input.options.map((option, i) => {
                      return (
                        <option
                          key={i}
                          id={option.id ? option.id : ""}
                          value={option.title}
                        >
                          {option.title}
                        </option>
                      );
                    })}
                  </select>
                  <span className="mandatory-text hide">Campo Obligatorio</span>
                </label>
              );
            }
            return (
              <label key={i}>
                {input.title}
                <input
                  title={input.typeVal}
                  type={input.type}
                  name={input.name}
                  id={input.name}
                  value={formData[input.name]}
                  autoComplete="off"
                  required
                />
                <span className="mandatory-text hide">Campo Obligatorio</span>
              </label>
            );
          })}
        </div>
        <div className="fws-btns-container">
          {inputData.steps[stepNumber].hasPrev && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setStepNumber(stepNumber - 1);
                setProgress(progress - 1);
              }}
              className="fws-btn"
              title="Anterior"
            >
              Anterior
            </button>
          )}
          {inputData.steps[stepNumber].hasNext && (
            <button onClick={handleNext} className="fws-btn" title="Siguiente">
              Siguiente
            </button>
          )}
          {inputData.steps[stepNumber].hasSend && (
            <button type="submit" className="fws-btn" title="Enviar">
              Enviar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormWithSteps;
