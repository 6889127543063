import React,{useContext} from "react";

//Components
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyNewCreditDocScreen from "../../components/BodyNewCreditDocScreen/BodyNewCreditDocScreen";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const NewCreditDocScreen = ({navbarData}) => {

  const {loading} = useContext(LoadingContext);

  return (
    <>
      {
        loading && <LoadingScreen />
      }
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <BodyNewCreditDocScreen />
    </>
  );
};

export default NewCreditDocScreen;
