import React from "react";

//Style
import "./DigitalSignSection.css";

//Components
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const DigitalSignSection = ({ buttons }) => {
  return (
    <section className="digital-sign-section" id="finance">
      <div className="digital-sign-subcontainer">
        <div className="ds-info-container">
          <h1 className="ds-info-title">
            TE INVITAMOS A <strong>SER PARTE</strong> DE NUESTRA RED DE MÁS DE
            1.000 <strong>INTERMEDIARIOS ALIADOS</strong>
          </h1>
          <p>
            Si eres Intermediario de seguros, quieres financiar y ganar dinero
            en efectivo con nosotros, déjanos tus datos y nos pondremos en
            contacto.
          </p>
          <div className="ds-info-btns-container">
            <div className="btn-container">
              <ButtonComponent btn={buttons[0]} />
            </div>
            <div className="btn-container">
              <ButtonComponent btn={buttons[1]} />
            </div>
          </div>
        </div>
        <img className="man-img" src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/man-deco-img.webp" alt="" loading="lazy" />
      </div>
      <div className="digital-sign-btn-subcontainer">
        <h1 className="dsb-title">
          {" "}
          <strong>FIRMA DIGITAL</strong> DESDE CUALQUIER{" "}
          <strong>DISPOSITIVO</strong>
        </h1>
        <div className="ds-btn-subsection">
          <ButtonComponent btn={buttons[2]} />
        </div>
      </div>
    </section>
  );
};

export default DigitalSignSection;
