import React from "react";

//Data
import formInputsData from '../../json/ProtoOcr/protoOcrFormDocs.json';

//Component
import FormDocs from "../FormDocs/FormDocs";

const BodyDocPolicyScreen = () => {
  return (
    <section className="new-credit-docs-section">
      <div className="ncd-subcontainer">
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/ncd-deco-img.webp"
          alt=""
          className="ncd-deco-img"
          loading="lazy"
        />
        <div className="ncd-form-container">
          <FormDocs inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  );
};

export default BodyDocPolicyScreen;
