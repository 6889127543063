import React from "react";

//Style
import "./BodyNewCreditFormScreen.css";

//Data
import formInputsData from "../../json/FormSecondStep/formSecondStepNewCredit.json";

//Components
import FormWithSteps from "../FormWithSteps/FormWithSteps";

const BodyNewCreditFormScreen = () => {
  return (
    <section className="new-credit-form-section">
      <div className="ncf-subcontainer">
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/steps-form/step-2-deco-img.webp"
          alt=""
          className="ncf-deco-img"
          loading="lazy"
        />
        <div className="ncf-form-container">
          <FormWithSteps inputData={formInputsData.data[0]} />
        </div>
      </div>
    </section>
  );
};

export default BodyNewCreditFormScreen;
